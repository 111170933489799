import { QueryParamName, QueryParams } from './queryParams';

import { AppSiteNavItem } from 'gatsby/types';
import { currentRouteKeyStore } from 'state/stores/nav';
import { findRouteInTree } from 'lib/navigation/global';
import { useAppNavTree } from 'state/context/nav';
import { useLocation } from '@reach/router';
import { useReadable } from 'lib/react-svelte/reactifyStores';

export function useQueryParams<K extends QueryParamName>(param: K) {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  return params.get(param) as QueryParams[K] | null;
}

type QueryParamsParsed = {
  [k: string]: string | number | boolean;
};

export function useQueryParamsObj(): QueryParamsParsed {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const output: QueryParamsParsed = {};
  for (const [k, v] of params.entries()) {
    if (v === 'true') {
      output[k] = true;
      continue;
    }

    if (v === 'false') {
      output[k] = false;
      continue;
    }

    const numeric = Number(v);

    if (typeof numeric === 'number' && !Number.isNaN(numeric)) {
      output[k] = numeric;
      continue;
    }

    output[k] = v;
  }

  return output;
}

/**
 * NOTE: useLocation is preferred if possible
 * because Browser wrapPageElement component will only update currentRouteKeyStore
 * as an effect, which means currentRouteKeyStore will only be updated after
 * the new route has mounted
 */
export function useCurrentRoute(): AppSiteNavItem | undefined {
  const appNav = useAppNavTree();
  const currentRouteKey = useReadable(currentRouteKeyStore);
  return currentRouteKey && findRouteInTree(appNav, currentRouteKey);
}
