import { Heading, TextSize } from 'components/atoms/typography';

import { LiquidSpinner } from 'components/atoms/icons/spinner/Liquid';
import React from 'react';

const PageLoading: React.FC<{ message: string }> = ({ message }) => {
  return (
    <div className="flex flex-col items-center justify-center">
      <LiquidSpinner className="h-rhythm10 w-rhythm10" />
      <Heading level={1} size={TextSize.GreatPrimer} className="text-center">
        {message}
      </Heading>
    </div>
  );
};

export default PageLoading;
