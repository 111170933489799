import { NotificationErrorLevel } from '@svelte/lib/notifications/types';
import { assertUnreachable, makeClassNames } from 'lib/util';

import React from 'react';

type ColourCss = {
  bar: string;
  background: string;
  color: string;
};

export function coloursOfLevel(level: NotificationErrorLevel): ColourCss {
  switch (level) {
    case NotificationErrorLevel.Success:
      return {
        bar: 'after:bg-emerald-400',
        background: 'bg-emerald-50',
        color: 'text-emerald-400'
      };
    case NotificationErrorLevel.Info:
      return {
        bar: 'after:bg-primary-400',
        background: 'bg-primary-100',
        color: 'text-primary-400'
      };
    case NotificationErrorLevel.Error:
      return {
        bar: 'after:bg-red-400',
        background: 'bg-red-50',
        color: 'text-red-400'
      };
    case NotificationErrorLevel.Warn:
      return {
        bar: 'after:bg-orange-400',
        background: 'bg-orange-50',
        color: 'text-orange-400'
      };
    default:
      assertUnreachable(level);
  }
}

type Props = PropsWithChildren & {
  className?: string;
  level?: NotificationErrorLevel;
};

const InfoBox: React.FC<Props> = ({
  children,
  className,
  level = NotificationErrorLevel.Info
}) => {
  const colours = coloursOfLevel(level);

  return (
    <div className={makeClassNames('relative', className, colours.background)}>
      <div
        className={`h-full w-rhythm1 flex-shrink-0 after:absolute after:inset-0 after:w-rhythm1 after:content-[''] ${colours.bar}`}
      />
      <div className="ml-rhythm1 h-full p-rhythm0">{children}</div>
    </div>
  );
};

export default InfoBox;
