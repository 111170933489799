import { BodyText, TextSize } from 'components/atoms/typography';
import React, { useRef } from 'react';

import InfoBox from '../info/Box';
import { makeClassNames } from 'lib/util';
import { NotificationErrorLevel } from '@svelte/lib/notifications/types';

const PROGRESS_CIRCLE_RADIUS = 40;
const PROGRESS_CIRCLE_SIZE = 104;

const ProgressCircle: React.FC<{ remaining: number }> = ({ remaining }) => {
  const circumference = PROGRESS_CIRCLE_RADIUS * 2 * Math.PI;

  const startValue = useRef(remaining);

  return (
    <div className="relative flex h-[100px] w-[120px] items-center justify-center overflow-hidden rounded-full">
      <svg
        className="h-full w-full -rotate-90"
        viewBox={`0 0 ${PROGRESS_CIRCLE_SIZE} ${PROGRESS_CIRCLE_SIZE}`}
      >
        <circle
          style={{ animationDuration: `${startValue.current}s` }}
          strokeWidth="8"
          stroke="currentColor"
          fill="transparent"
          // NOTE: duration is overridden, but this one is the same as Toast to not create new CSS rule
          className={`animate-[strokeOffsetToZero_3s_linear_forwards] text-orange-500`}
          r={PROGRESS_CIRCLE_RADIUS}
          cx={PROGRESS_CIRCLE_SIZE / 2}
          cy={PROGRESS_CIRCLE_SIZE / 2}
          strokeDasharray={circumference}
          strokeDashoffset={circumference}
        />
      </svg>
      <span className="absolute">{remaining}</span>
    </div>
  );
};

type RateLimitInfoBoxProps = Readonly<{
  className?: string;
  message: string;
  rateLimitRemaining?: number;
}>;

const RateLimitInfoBox: React.FC<RateLimitInfoBoxProps> = ({
  className,
  message,
  rateLimitRemaining
}) => {
  if (!rateLimitRemaining) {
    return null;
  }

  return (
    <InfoBox
      className={makeClassNames(className)}
      level={NotificationErrorLevel.Warn}
    >
      <div className="flex space-x-3">
        <ProgressCircle remaining={rateLimitRemaining} />
        <div className="flex flex-col justify-center">
          <BodyText withoutSpacing size={TextSize.LongPrimer}>
            {message}
          </BodyText>
        </div>
      </div>
    </InfoBox>
  );
};

export default RateLimitInfoBox;
