import { BodyText, Heading } from 'components/atoms/typography';

import React from 'react';

const PageErrorMessage: React.FC<{ message: string }> = ({ message }) => {
  return (
    <>
      <Heading level={1}>Oops!</Heading>
      <BodyText measure="measure-narrow" className="text-center">
        {message}
      </BodyText>
    </>
  );
};

export default PageErrorMessage;
