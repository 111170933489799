import React from 'react';
import { makeClassNames } from 'lib/util';

import * as styles from './liquid.module.css';

type Props = {
  className?: string;
};

const config = {
  mainStrokeColor: 'stroke-primary',
  mainFillColor: 'fill-primary',
  strokeWidth: 'stroke-4',
  strokeColor1: 'stroke-secondary',
  fillColor1: 'fill-secondary',
  strokeColor2: 'stroke-primary-200',
  strokeColor3: 'stroke-secondary-200',
  fillColor2: 'fill-secondary-200'
};

export const LiquidSpinner: React.FC<Props> = ({ className }) => {
  const {
    mainFillColor,
    mainStrokeColor,
    strokeWidth,
    strokeColor1,
    fillColor1,
    strokeColor2,
    strokeColor3,
    fillColor2
  } = config;

  return (
    <svg
      width="140"
      height="140"
      viewBox="0 0 280 280"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={makeClassNames(styles.spinner, className)}
    >
      {/* Horizontal line */}
      <g>
        <line
          x1="59.9833"
          y1="140.333"
          x2="219.978"
          y2="139"
          className={makeClassNames(mainStrokeColor, strokeWidth)}
        />
        <circle cx="60" cy="140" r="5" className={mainFillColor} />
        <circle cx="220" cy="139" r="5" className={mainFillColor} />
      </g>
      {/* Hexagon */}
      <path
        d="M109.957 122.655L140 105.309L170.043 122.655V157.345L140 174.691L109.957 157.345V122.655Z"
        className={makeClassNames(styles.circle, mainStrokeColor, strokeWidth)}
      />
      {/* Circle inside hexagon */}
      <circle
        className={makeClassNames(styles.circle, strokeWidth, strokeColor1)}
        cx="140"
        cy="140"
        r="13"
      />
      <circle
        className={makeClassNames(styles.circle, strokeWidth, strokeColor3)}
        cx="110"
        cy="192"
        r="13"
      />
      <circle
        className={makeClassNames(
          styles.circle,
          styles.circleS,
          strokeWidth,
          strokeColor2
        )}
        cx="85"
        cy="232"
        r="8"
      />
      <circle
        className={makeClassNames(styles.circle, strokeWidth, strokeColor2)}
        cx="170"
        cy="88"
        r="13"
      />
      <circle
        className={makeClassNames(styles.circle, styles.circleS, fillColor2)}
        cx="110"
        cy="192"
        r="5"
      />
      <circle
        className={makeClassNames(styles.circle, styles.circleS, fillColor1)}
        cx="185"
        cy="61"
        r="5"
      />
    </svg>
  );
};
